var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "memberInfoForm-wrapper" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "form",
            attrs: { form: _vm.form },
            on: { update: _vm.update },
            scopedSlots: _vm._u([
              {
                key: "saveBeforeSlot",
                fn: function () {
                  return [
                    _vm.showReturn
                      ? _c("v-button", {
                          attrs: { text: "返回" },
                          on: { click: _vm.returnClick },
                        })
                      : _vm._e(),
                    _c("v-button", {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loading,
                          expression: "loading",
                        },
                      ],
                      attrs: { type: "success", text: "保存" },
                      on: { click: _vm.submitBefore },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          },
          "form-panel",
          _vm.config,
          false
        ),
        [
          _c(
            "template",
            { slot: "headerSlot" },
            [
              _c("v-button", {
                attrs: { text: "返回" },
                on: { click: _vm.goBack },
              }),
            ],
            1
          ),
          _c(
            "col2-detail",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.pageLoading,
                  expression: "pageLoading",
                },
              ],
              attrs: { span: 24 },
            },
            [
              _c(
                "col2-block",
                { attrs: { title: "个人信息" } },
                [
                  _c(
                    "col2-item",
                    { staticClass: "f-wh" },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "姓名",
                            prop: "userName",
                            rules: [
                              {
                                required: true,
                                message: "请输入姓名",
                                trigger: "blur",
                              },
                            ],
                          },
                        },
                        [
                          _c("v-input", {
                            attrs: {
                              placeholder: "请输入姓名",
                              maxlength: 20,
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.userName,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "userName", $$v)
                              },
                              expression: "form.userName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "性别",
                            prop: "sex",
                            rules: [
                              {
                                required: true,
                                message: "请选择性别",
                                trigger: ["blur", "change"],
                              },
                            ],
                          },
                        },
                        [
                          _c("v-select", {
                            attrs: { options: _vm.sexOps, width: _vm.width },
                            model: {
                              value: _vm.form.sex,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "sex", $$v)
                              },
                              expression: "form.sex",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "手机号",
                            prop: "phone",
                            rules: [
                              {
                                required: true,
                                validator: _vm.validatePhone,
                                trigger: "blur",
                              },
                            ],
                          },
                        },
                        [
                          _c("v-input", {
                            attrs: {
                              disabled: !!_vm.id && !_vm.editMobile,
                              placeholder: "请输入手机号",
                              maxlength: 11,
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.phone,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "phone", $$v)
                              },
                              expression: "form.phone",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "证件类型",
                            prop: "cardType",
                            rules: [
                              {
                                required: true,
                                message: "请选择证件类型",
                                trigger: "change",
                              },
                            ],
                          },
                        },
                        [
                          _c("v-select", {
                            attrs: { options: _vm.cardTypeOps },
                            model: {
                              value: _vm.form.cardType,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "cardType", $$v)
                              },
                              expression: "form.cardType",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "证件号",
                            prop: "cardNum",
                            rules: [
                              {
                                required: true,
                                validator: _vm.validateCardNum,
                                trigger: "blur",
                              },
                            ],
                          },
                        },
                        [
                          _c("v-input", {
                            attrs: {
                              placeholder: "请输入证件号",
                              maxlength: 18,
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.cardNum,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "cardNum", $$v)
                              },
                              expression: "form.cardNum",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "生日", prop: "birthday" } },
                        [
                          _c("v-datepicker", {
                            attrs: {
                              type: "date",
                              format: "YYYY-MM-DD",
                              maxDate: new Date(),
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.birthday,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "birthday", $$v)
                              },
                              expression: "form.birthday",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "国籍", prop: "country" } },
                        [
                          _c("v-select", {
                            attrs: {
                              filterable: "",
                              options: _vm.countryOps,
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.country,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "country", $$v)
                              },
                              expression: "form.country",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "民族", prop: "nation" } },
                        [
                          _c("v-select", {
                            attrs: {
                              filterable: "",
                              options: _vm.nationOps,
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.nation,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "nation", $$v)
                              },
                              expression: "form.nation",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "籍贯",
                            prop: "nativePlaceArea",
                            rules: [
                              {
                                required: true,
                                message: "请选择籍贯",
                                trigger: "change",
                              },
                            ],
                          },
                        },
                        [
                          _c("area-select", {
                            attrs: {
                              codes: _vm.nativePlaceArray,
                              "detail-addr": false,
                            },
                            on: {
                              "update:codes": function ($event) {
                                _vm.nativePlaceArray = $event
                              },
                              change: _vm.nativePlaceChange,
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "电话（家庭）",
                            prop: "phoneNum",
                            rules: [
                              {
                                validator: _vm.validatePhone1,
                                trigger: "blur",
                              },
                            ],
                          },
                        },
                        [
                          _c("v-input", {
                            attrs: {
                              placeholder: "请输入电话",
                              maxlength: 11,
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.phoneNum,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "phoneNum", $$v)
                              },
                              expression: "form.phoneNum",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "个人邮箱",
                            prop: "emailAddress",
                            rules: [
                              { validator: _vm.validateEmail, trigger: "blur" },
                            ],
                          },
                        },
                        [
                          _c("v-input", {
                            attrs: {
                              placeholder: "请输入个人邮箱",
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.emailAddress,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "emailAddress", $$v)
                              },
                              expression: "form.emailAddress",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "col2-item",
                    { staticClass: "f-wh2" },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "个人证件照", prop: "facePhoto" } },
                        [
                          _c("v-uploader", {
                            attrs: {
                              action: _vm.uploadURL,
                              imgUrls: _vm.form.facePhoto,
                              fileHeight: 197,
                              fileWidth: _vm.width,
                            },
                            on: {
                              "update:imgUrls": function ($event) {
                                return _vm.$set(_vm.form, "facePhoto", $event)
                              },
                              "update:img-urls": function ($event) {
                                return _vm.$set(_vm.form, "facePhoto", $event)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "生育状况" } },
                        [
                          _c("v-select", {
                            attrs: {
                              options: _vm.fertilityStatusOps,
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.fertilityStatus,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "fertilityStatus", $$v)
                              },
                              expression: "form.fertilityStatus",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "婚姻状况" } },
                        [
                          _c("v-select", {
                            attrs: {
                              options: _vm.maritalStatusOps,
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.maritalStatus,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "maritalStatus", $$v)
                              },
                              expression: "form.maritalStatus",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "毕业院校" } },
                        [
                          _c("v-input", {
                            attrs: {
                              placeholder: "请输入毕业院校",
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.graduateSchool,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "graduateSchool", $$v)
                              },
                              expression: "form.graduateSchool",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "所学专业" } },
                        [
                          _c("v-input", {
                            attrs: {
                              placeholder: "请输入所学专业",
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.major,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "major", $$v)
                              },
                              expression: "form.major",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "毕业时间" } },
                        [
                          _c("v-datepicker", {
                            attrs: {
                              type: "date",
                              format: "YYYY-MM-DD",
                              maxDate: new Date(),
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.graduateDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "graduateDate", $$v)
                              },
                              expression: "form.graduateDate",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "学历" } },
                        [
                          _c("v-select", {
                            attrs: {
                              options: _vm.educationDegreeOps,
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.educationDegree,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "educationDegree", $$v)
                              },
                              expression: "form.educationDegree",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "首次参加工作时间",
                            prop: "firstWorkTime",
                          },
                        },
                        [
                          _c("v-datepicker", {
                            attrs: {
                              type: "date",
                              format: "YYYY-MM-DD",
                              maxDate: new Date(),
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.firstWorkTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "firstWorkTime", $$v)
                              },
                              expression: "form.firstWorkTime",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("col2-item", { attrs: { span: 24 } }, [
                    _c(
                      "div",
                      { staticStyle: { display: "flex" } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "户口所在地",
                              prop: "registeredResidenceArea",
                              rules: {
                                validator: _vm.valiAreaList1,
                                required: true,
                                trigger: ["change", "blur"],
                              },
                            },
                          },
                          [
                            _c("area-select", {
                              attrs: {
                                codes: _vm.areaList1,
                                "detail-addr": false,
                              },
                              on: {
                                "update:codes": function ($event) {
                                  _vm.areaList1 = $event
                                },
                                change: _vm.areaChange1,
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              "label-width": "20px",
                              prop: "registeredResidenceAddress",
                              rules: [
                                {
                                  required: true,
                                  message: "请输入详细地址",
                                  trigger: "blur",
                                },
                              ],
                            },
                          },
                          [
                            _c("v-input", {
                              attrs: {
                                placeholder: "请输入详细地址",
                                width: _vm.width2,
                              },
                              model: {
                                value: _vm.form.registeredResidenceAddress,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    "registeredResidenceAddress",
                                    $$v
                                  )
                                },
                                expression: "form.registeredResidenceAddress",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("col2-item", { attrs: { span: 24 } }, [
                    _c(
                      "div",
                      { staticStyle: { display: "flex" } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "现居住地",
                              prop: "currentResidenceArea",
                              rules: {
                                validator: _vm.valiAreaList2,
                                required: true,
                                trigger: ["change", "blur"],
                              },
                            },
                          },
                          [
                            _c("area-select", {
                              attrs: {
                                codes: _vm.areaList2,
                                "detail-addr": false,
                              },
                              on: {
                                "update:codes": function ($event) {
                                  _vm.areaList2 = $event
                                },
                                change: _vm.areaChange2,
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              "label-width": "20px",
                              prop: "currentResidenceAddress",
                              rules: [
                                {
                                  required: true,
                                  message: "请输入详细地址",
                                  trigger: "blur",
                                },
                              ],
                            },
                          },
                          [
                            _c("v-input", {
                              attrs: {
                                placeholder: "请输入详细地址",
                                width: _vm.width2,
                              },
                              model: {
                                value: _vm.form.currentResidenceAddress,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    "currentResidenceAddress",
                                    $$v
                                  )
                                },
                                expression: "form.currentResidenceAddress",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("col2-item", { attrs: { span: 24 } }, [
                    _c(
                      "div",
                      { staticStyle: { display: "flex" } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "证件照片",
                              prop: "cardPhotoFront",
                            },
                          },
                          [
                            _c("v-uploader", {
                              attrs: {
                                action: _vm.uploadURL,
                                imgUrls: _vm.form.cardPhotoFront,
                                fileHeight: 200,
                                fileWidth: 340,
                              },
                              on: {
                                "update:imgUrls": function ($event) {
                                  return _vm.$set(
                                    _vm.form,
                                    "cardPhotoFront",
                                    $event
                                  )
                                },
                                "update:img-urls": function ($event) {
                                  return _vm.$set(
                                    _vm.form,
                                    "cardPhotoFront",
                                    $event
                                  )
                                },
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "tip",
                                  fn: function () {
                                    return [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            width: "340px",
                                            "text-align": "center",
                                            color: "#606266",
                                          },
                                        },
                                        [_vm._v("国徽面")]
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              "label-width": "30px",
                              prop: "cardPhotoBack",
                            },
                          },
                          [
                            _c("v-uploader", {
                              attrs: {
                                action: _vm.uploadURL,
                                imgUrls: _vm.form.cardPhotoBack,
                                fileHeight: 200,
                                fileWidth: 340,
                              },
                              on: {
                                "update:imgUrls": function ($event) {
                                  return _vm.$set(
                                    _vm.form,
                                    "cardPhotoBack",
                                    $event
                                  )
                                },
                                "update:img-urls": function ($event) {
                                  return _vm.$set(
                                    _vm.form,
                                    "cardPhotoBack",
                                    $event
                                  )
                                },
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "tip",
                                  fn: function () {
                                    return [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            width: "340px",
                                            "text-align": "center",
                                            color: "#606266",
                                          },
                                        },
                                        [_vm._v("头像面")]
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "col2-block",
                { attrs: { title: "企业管理" } },
                [
                  _c(
                    "col2-item",
                    { staticClass: "f-wh" },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "所属部门",
                            prop: "orgId",
                            rules: [
                              {
                                required: true,
                                message: "请选择所属部门",
                                trigger: "change",
                              },
                            ],
                          },
                        },
                        [
                          _c("select-tree", {
                            attrs: {
                              value: _vm.form.orgId,
                              data: _vm.treeSelectData,
                              placeholder: "查询所属部门",
                              treeData: _vm.treeData,
                              treeProps: _vm.treeProps,
                              highlightCurrent: true,
                              filterNodeMethod: _vm.filterNodeMethod,
                              "expand-on-click-node": false,
                            },
                            on: {
                              "update:value": function ($event) {
                                return _vm.$set(_vm.form, "orgId", $event)
                              },
                              "update:data": function ($event) {
                                _vm.treeSelectData = $event
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "工号",
                            prop: "jobNum",
                            rules: [
                              {
                                required: true,
                                message: "请输入工号",
                                trigger: "blur",
                              },
                            ],
                          },
                        },
                        [
                          _c("v-input", {
                            attrs: {
                              placeholder: "请输入工号",
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.jobNum,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "jobNum", $$v)
                              },
                              expression: "form.jobNum",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "职级", prop: "jobRank" } },
                        [
                          _c("v-select", {
                            attrs: {
                              options: _vm.jobRankOps,
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.jobRank,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "jobRank", $$v)
                              },
                              expression: "form.jobRank",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "入职日期", prop: "jobEnterDate" } },
                        [
                          _c("v-datepicker", {
                            attrs: {
                              type: "date",
                              format: "YYYY-MM-DD",
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.jobEnterDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "jobEnterDate", $$v)
                              },
                              expression: "form.jobEnterDate",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "col2-item",
                    { staticClass: "f-wh2" },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "岗位",
                            prop: "duty",
                            rules: [
                              {
                                required: true,
                                message: "请输入岗位",
                                trigger: ["blur", "change"],
                              },
                            ],
                          },
                        },
                        [
                          _c("v-input", {
                            attrs: {
                              placeholder: "请输入岗位",
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.duty,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "duty", $$v)
                              },
                              expression: "form.duty",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "公司邮箱",
                            prop: "jobEmailAddress",
                            rules: [
                              { validator: _vm.validateEmail, trigger: "blur" },
                            ],
                          },
                        },
                        [
                          _c("v-input", {
                            attrs: {
                              placeholder: "请输入公司邮箱",
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.jobEmailAddress,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "jobEmailAddress", $$v)
                              },
                              expression: "form.jobEmailAddress",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "薪档", prop: "salaryGrade" } },
                        [
                          _c("v-select", {
                            attrs: {
                              options: _vm.salaryGradeOps,
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.salaryGrade,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "salaryGrade", $$v)
                              },
                              expression: "form.salaryGrade",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "离职时间", prop: "jobQuitDate" } },
                        [
                          _c("v-datepicker", {
                            attrs: {
                              type: "date",
                              format: "YYYY-MM-DD",
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.jobQuitDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "jobQuitDate", $$v)
                              },
                              expression: "form.jobQuitDate",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "col2-block",
                { attrs: { title: "账号管理" } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "用户名",
                        prop: "loginName",
                        rules: [
                          {
                            required: !_vm.loginNameDisabled,
                            message: "请输入用户名",
                            trigger: ["blur", "change"],
                          },
                        ],
                      },
                    },
                    [
                      _c("v-input", {
                        attrs: {
                          placeholder: "请输入用户名",
                          disabled: !!_vm.loginNameDisabled,
                          width: _vm.width,
                        },
                        model: {
                          value: _vm.form.loginName,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "loginName", $$v)
                          },
                          expression: "form.loginName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("v-input", {
                    staticStyle: { position: "fixed", bottom: "-9999px" },
                  }),
                  _c("v-input", {
                    staticStyle: { position: "fixed", bottom: "-9999px" },
                    attrs: { type: "password" },
                  }),
                  !_vm.id
                    ? _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "用户密码",
                                prop: "pwd",
                                rules: [
                                  {
                                    required: true,
                                    validator: _vm.validatePass1,
                                    trigger: "blur",
                                  },
                                ],
                              },
                            },
                            [
                              _c("v-input", {
                                ref: "input1",
                                attrs: {
                                  type: "password",
                                  placeholder: "请输入用户密码",
                                  width: _vm.width,
                                  autocomplete: "new-password",
                                },
                                model: {
                                  value: _vm.form.pwd,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "pwd", $$v)
                                  },
                                  expression: "form.pwd",
                                },
                              }),
                            ],
                            1
                          ),
                          _c("v-input", {
                            staticStyle: {
                              position: "fixed",
                              bottom: "-9999px",
                            },
                            attrs: { type: "password" },
                          }),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                "label-width": "20px",
                                prop: "pwd2",
                                rules: [
                                  {
                                    required: true,
                                    validator: _vm.validatePass,
                                    trigger: "blur",
                                  },
                                ],
                              },
                            },
                            [
                              _c("v-input", {
                                ref: "input2",
                                attrs: {
                                  type: "password",
                                  placeholder: "请确认用户密码",
                                  autocomplete: "new-password",
                                  width: _vm.width,
                                },
                                model: {
                                  value: _vm.form.pwd2,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "pwd2", $$v)
                                  },
                                  expression: "form.pwd2",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "账号类型",
                        prop: "roleType",
                        rules: [
                          {
                            required: true,
                            message: "请选择账号类型",
                            trigger: "change",
                          },
                        ],
                      },
                    },
                    [
                      _c("v-select", {
                        attrs: {
                          options: _vm.roleTypeFilterOps,
                          disabled: !!_vm.defaultRoleType,
                          width: _vm.width,
                        },
                        on: { change: _vm.roleTypeChange },
                        model: {
                          value: _vm.form.roleType,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "roleType", $$v)
                          },
                          expression: "form.roleType",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.form.roleType != "107" && _vm.form.roleType
                    ? _c(
                        "div",
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "管理范围",
                                prop: "organizeId",
                                rules: [
                                  {
                                    required: true,
                                    message: "请选择管理范围",
                                    trigger: "change",
                                  },
                                ],
                              },
                            },
                            [
                              _c(
                                "v-select2",
                                _vm._b(
                                  {
                                    staticStyle: { display: "inline-block" },
                                    attrs: {
                                      placeholder: "查询管理范围",
                                      subjoin: _vm.roleExtraParams,
                                      disabled: !!_vm.defaultOrganizeId,
                                      width: _vm.width,
                                    },
                                    on: { onChange: _vm.organizeChange },
                                    model: {
                                      value: _vm.form.organizeId,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "organizeId", $$v)
                                      },
                                      expression: "form.organizeId",
                                    },
                                  },
                                  "v-select2",
                                  _vm.organizeParams,
                                  false
                                )
                              ),
                              _c("span", { staticClass: "mar-l" }, [
                                _vm._v("注：请选择管理项目或网格范围"),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "角色",
                                prop: "roleId",
                                rules: [
                                  {
                                    required: true,
                                    message: "请选择角色",
                                    trigger: "change",
                                  },
                                ],
                              },
                            },
                            [
                              _c(
                                "v-select2",
                                _vm._b(
                                  {
                                    attrs: {
                                      placeholder: "查询角色",
                                      subjoin: _vm.rolesExtraParams,
                                      width: _vm.width,
                                    },
                                    model: {
                                      value: _vm.form.roleId,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "roleId", $$v)
                                      },
                                      expression: "form.roleId",
                                    },
                                  },
                                  "v-select2",
                                  _vm.roleParams,
                                  false
                                )
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    { attrs: { label: "账号状态", prop: "accountStatus" } },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.statusMap[_vm.form.accountStatus]) +
                          "\n          "
                      ),
                      !!_vm.id && _vm.form.loginName !== "ad_01"
                        ? _c("v-button", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.form.accountStatus === 0,
                                expression: "form.accountStatus === 0",
                              },
                            ],
                            staticClass: "mar-l",
                            attrs: {
                              text: "关闭",
                              type: "text",
                              permission: "delete",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.updateStatus(0)
                              },
                            },
                          })
                        : _vm._e(),
                      !!_vm.id && _vm.form.loginName !== "ad_01"
                        ? _c("v-button", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.form.accountStatus === 2,
                                expression: "form.accountStatus === 2",
                              },
                            ],
                            staticClass: "mar-l",
                            attrs: {
                              text: "开启",
                              type: "text",
                              permission: "delete",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.updateStatus(1)
                              },
                            },
                          })
                        : _vm._e(),
                      !!_vm.id && _vm.form.loginName !== "ad_01"
                        ? _c("v-button", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.form.accountStatus === 0,
                                expression: "form.accountStatus === 0",
                              },
                            ],
                            staticClass: "mar-l",
                            attrs: {
                              text: "重置密码",
                              type: "text",
                              permission: "resetPwd",
                            },
                            on: { click: _vm.resetPwd },
                          })
                        : _vm._e(),
                      !!_vm.id && _vm.form.loginName !== "ad_01"
                        ? _c("v-button", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.form.accountStatus === 0,
                                expression: "form.accountStatus === 0",
                              },
                            ],
                            staticClass: "mar-l",
                            attrs: {
                              text: "解锁",
                              type: "text",
                              permission: "unlocked",
                            },
                            on: { click: _vm.unlockID },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "账号有效截止日期",
                        prop: "accountValidDate",
                        rules: [
                          {
                            required: true,
                            message: "请选择账号有效截止日期",
                            trigger: "change",
                          },
                        ],
                      },
                    },
                    [
                      _c("v-datepicker", {
                        attrs: {
                          type: "date",
                          format: "YYYY-MM-DD",
                          width: _vm.width,
                          minDate: new Date(),
                        },
                        model: {
                          value: _vm.form.accountValidDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "accountValidDate", $$v)
                          },
                          expression: "form.accountValidDate",
                        },
                      }),
                      _c("span", { staticClass: "mar-l" }, [
                        _vm._v("注：截止到当日23:59:59"),
                      ]),
                    ],
                    1
                  ),
                  _vm.form.roleType === "107" ||
                  (_vm.form.roleType === "101" && _vm.form.organizeId)
                    ? [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "所属组织",
                              required: _vm.form.roleType === "107",
                              prop: "roleType",
                              rules: [
                                {
                                  validator: _vm.validatorOrgId,
                                  trigger: "change",
                                },
                              ],
                            },
                          },
                          [
                            _c("chosenListPanel", {
                              attrs: { list: _vm.orgList },
                              on: {
                                "update:list": function ($event) {
                                  _vm.orgList = $event
                                },
                                select: _vm.toSelectOrg,
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                  _vm.oauthShow
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "授权物业服务", prop: "oauth" } },
                        [
                          _c(
                            "div",
                            { staticClass: "check-panel" },
                            _vm._l(_vm.oauthList, function (item, index) {
                              return _c("v-checkbox", {
                                key: index,
                                attrs: {
                                  index: index,
                                  type: "border",
                                  label: item.name,
                                },
                                on: {
                                  change: (val) =>
                                    _vm.authSelect(val, item, index),
                                },
                                model: {
                                  value: item.checked,
                                  callback: function ($$v) {
                                    _vm.$set(item, "checked", $$v)
                                  },
                                  expression: "item.checked",
                                },
                              })
                            }),
                            1
                          ),
                          _c("div", { staticStyle: { color: "#b75151" } }, [
                            _vm._v(
                              "\n            注：授权服务之后，该用户如果具有园区管理app“服务工单”的权限，就可以查看上述选中服务的工单.\n          "
                            ),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm.oauthStoreShow
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "授权门店", prop: "oauth" } },
                        [
                          _c(
                            "div",
                            { staticClass: "check-panel" },
                            _vm._l(_vm.oauthStoreList, function (item, index) {
                              return _c("v-checkbox", {
                                key: index,
                                attrs: {
                                  index: index,
                                  type: "border",
                                  label: item.name,
                                },
                                on: {
                                  change: (val) =>
                                    _vm.authStoreSelect(val, item, index),
                                },
                                model: {
                                  value: item.checked,
                                  callback: function ($$v) {
                                    _vm.$set(item, "checked", $$v)
                                  },
                                  expression: "item.checked",
                                },
                              })
                            }),
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("multi-select", {
        attrs: {
          title: "组织列表",
          isShow: _vm.isShow,
          searchUrl: _vm.getOrgRelationListURL,
          extraParams: _vm.extraParams,
          headers: _vm.orgTableHeader,
          searchParams: _vm.searchParams,
          responseParams: _vm.responseParams,
          responseKey: _vm.responseKey,
          backFill: _vm.orgList,
        },
        on: {
          "update:isShow": function ($event) {
            _vm.isShow = $event
          },
          "update:is-show": function ($event) {
            _vm.isShow = $event
          },
          "update:backFill": function ($event) {
            _vm.orgList = $event
          },
          "update:back-fill": function ($event) {
            _vm.orgList = $event
          },
          callback: _vm.onOrgSelect,
        },
        scopedSlots: _vm._u([
          {
            key: "searchSlot",
            fn: function () {
              return [
                _c("v-input", {
                  attrs: { label: "组织名称" },
                  model: {
                    value: _vm.searchParams.orgName,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "orgName", $$v)
                    },
                    expression: "searchParams.orgName",
                  },
                }),
                _c(
                  "v-select2",
                  _vm._b(
                    {
                      attrs: { label: "组织标签" },
                      model: {
                        value: _vm.searchParams.orgTagId,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParams, "orgTagId", $$v)
                        },
                        expression: "searchParams.orgTagId",
                      },
                    },
                    "v-select2",
                    _vm.orgTagParams,
                    false
                  )
                ),
                _vm.form.roleType === "107"
                  ? _c(
                      "v-select2",
                      _vm._b(
                        {
                          attrs: {
                            subjoin: _vm.roleExtraParams,
                            label: "角色",
                          },
                          model: {
                            value: _vm.searchParams.roleId,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchParams, "roleId", $$v)
                            },
                            expression: "searchParams.roleId",
                          },
                        },
                        "v-select2",
                        _vm.roleParams,
                        false
                      )
                    )
                  : _vm._e(),
                _vm.form.roleType !== "101"
                  ? _c(
                      "v-select2",
                      _vm._b(
                        {
                          attrs: { label: "关联项目" },
                          model: {
                            value: _vm.searchParams.communityId,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchParams, "communityId", $$v)
                            },
                            expression: "searchParams.communityId",
                          },
                        },
                        "v-select2",
                        _vm.communityParams,
                        false
                      )
                    )
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }