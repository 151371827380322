<template>
  <div class="memberInfoForm-wrapper">
    <form-panel
      ref="form"
      v-bind="config"
      :form="form"
      @update="update"
    >
      <template slot="headerSlot">
        <v-button text="返回" @click="goBack"></v-button>
      </template>
      <col2-detail :span="24" v-loading="pageLoading">
        <col2-block title="个人信息">
          <col2-item class="f-wh">
            <el-form-item label="姓名" prop="userName"
              :rules="[ { required: true, message: '请输入姓名', trigger: 'blur' } ]"
            >
              <v-input v-model="form.userName" placeholder="请输入姓名" :maxlength="20" :width="width"/>
            </el-form-item>
            <el-form-item label="性别" prop="sex"
              :rules="[ { required: true, message: '请选择性别', trigger: ['blur', 'change'] } ]"
            >
              <v-select v-model="form.sex" :options="sexOps" :width="width"/>
            </el-form-item>
            <el-form-item label="手机号" prop="phone"
              :rules="[{ required: true, validator: validatePhone, trigger: 'blur' }]"
            >
              <v-input :disabled="!!id && !editMobile"
                v-model="form.phone"
                placeholder="请输入手机号"
                :maxlength="11"
                :width="width"
              />
            </el-form-item>
            <el-form-item
              label="证件类型"
              prop="cardType"
              :rules="[
                {
                  required: true,
                  message: '请选择证件类型',
                  trigger: 'change',
                },
              ]"
            >
              <v-select
                v-model="form.cardType"
                :options="cardTypeOps"
              ></v-select>
            </el-form-item>
            <el-form-item label="证件号" prop="cardNum"
              :rules="[ { required: true, validator: validateCardNum, trigger: 'blur' } ]"
            >
              <v-input
                v-model="form.cardNum"
                placeholder="请输入证件号"
                :maxlength="18"
                :width="width"
              />
            </el-form-item>
            <el-form-item label="生日" prop="birthday">
              <v-datepicker v-model="form.birthday" type="date" format="YYYY-MM-DD" :maxDate="new Date()" :width="width"/>
            </el-form-item>
            <el-form-item label="国籍" prop="country">
              <v-select v-model="form.country" filterable :options="countryOps" :width="width"/>
            </el-form-item>
            <el-form-item label="民族" prop="nation">
              <v-select v-model="form.nation" filterable :options="nationOps" :width="width"/>
            </el-form-item>
            <!-- <el-form-item label="籍贯" prop="nativePlace">
              <v-input v-model="form.nativePlace" placeholder="请输入籍贯" :width="width" />
            </el-form-item> -->
            <el-form-item
              label="籍贯"
              prop="nativePlaceArea"
              :rules="[
                { required: true, message: '请选择籍贯', trigger: 'change' },
              ]"
            >
              <area-select
                :codes.sync="nativePlaceArray"
                :detail-addr="false"
                @change="nativePlaceChange"
              >
              </area-select>
            </el-form-item>
            <el-form-item label="电话（家庭）" prop="phoneNum"
              :rules="[{ validator: validatePhone1, trigger: 'blur' }]"
            >
              <v-input v-model="form.phoneNum" placeholder="请输入电话" :maxlength="11" :width="width" />
            </el-form-item>
            <el-form-item label="个人邮箱" prop="emailAddress"
              :rules="[ { validator: validateEmail, trigger: 'blur' } ]"
            >
              <v-input v-model="form.emailAddress" placeholder="请输入个人邮箱" :width="width" />
            </el-form-item>
          </col2-item>
          <col2-item class="f-wh2">
            <el-form-item label="个人证件照" prop="facePhoto">
              <v-uploader
                :action="uploadURL"
                :imgUrls.sync="form.facePhoto"
                :fileHeight="197"
                :fileWidth="width"
              />
            </el-form-item>
            <el-form-item label="生育状况">
              <v-select v-model="form.fertilityStatus" :options="fertilityStatusOps" :width="width"/>
            </el-form-item>
            <el-form-item label="婚姻状况">
              <v-select v-model="form.maritalStatus" :options="maritalStatusOps" :width="width"/>
            </el-form-item>
            <el-form-item label="毕业院校">
              <v-input v-model="form.graduateSchool" placeholder="请输入毕业院校" :width="width" />
            </el-form-item>
            <el-form-item label="所学专业">
              <v-input v-model="form.major" placeholder="请输入所学专业" :width="width" />
            </el-form-item>
            <el-form-item label="毕业时间">
              <v-datepicker v-model="form.graduateDate" type="date" format="YYYY-MM-DD" :maxDate="new Date()" :width="width"/>
            </el-form-item>
            <el-form-item label="学历">
              <v-select v-model="form.educationDegree" :options="educationDegreeOps" :width="width"/>
            </el-form-item>

            <el-form-item label="首次参加工作时间" prop="firstWorkTime">
              <v-datepicker v-model="form.firstWorkTime" type="date" format="YYYY-MM-DD" :maxDate="new Date()" :width="width"/>
            </el-form-item>
          </col2-item>
          <col2-item :span="24">
            <div style="display: flex;">
              <el-form-item
                label="户口所在地"
                prop="registeredResidenceArea"
                :rules="{ validator: valiAreaList1, required: true, trigger: ['change', 'blur'] }"
              >
                <area-select
                  :codes.sync="areaList1"
                  :detail-addr="false"
                  @change="areaChange1"
                />
              </el-form-item>
              <el-form-item
                label-width="20px"
                prop="registeredResidenceAddress"
                :rules="[ { required: true, message: '请输入详细地址', trigger: 'blur' } ]"
              >
                <v-input v-model="form.registeredResidenceAddress" placeholder="请输入详细地址" :width="width2" />
              </el-form-item>
            </div>
          </col2-item>
          <col2-item :span="24">
            <div style="display: flex;">
              <el-form-item
                label="现居住地"
                prop="currentResidenceArea"
                :rules="{ validator: valiAreaList2, required: true, trigger: ['change', 'blur'] }"
              >
                <area-select
                  :codes.sync="areaList2"
                  :detail-addr="false"
                  @change="areaChange2"
                />
              </el-form-item>
              <el-form-item
                label-width="20px"
                prop="currentResidenceAddress"
                :rules="[ { required: true, message: '请输入详细地址', trigger: 'blur' } ]"
              >
                <v-input v-model="form.currentResidenceAddress" placeholder="请输入详细地址" :width="width2" />
              </el-form-item>
            </div>
          </col2-item>
          <col2-item :span="24">
            <div style="display: flex;">
              <el-form-item label="证件照片" prop="cardPhotoFront">
                <v-uploader
                  :action="uploadURL"
                  :imgUrls.sync="form.cardPhotoFront"
                  :fileHeight="200"
                  :fileWidth="340"
                >
                  <template #tip>
                    <div style="width:340px;text-align:center;color:#606266;">国徽面</div>
                  </template>
                </v-uploader>
              </el-form-item>
              <el-form-item label-width="30px" prop="cardPhotoBack">
                <v-uploader
                  :action="uploadURL"
                  :imgUrls.sync="form.cardPhotoBack"
                  :fileHeight="200"
                  :fileWidth="340"
                >
                  <template #tip>
                    <div style="width:340px;text-align:center;color:#606266;">头像面</div>
                  </template>
                </v-uploader>
              </el-form-item>
            </div>
          </col2-item>
        </col2-block>
        <col2-block title="企业管理">
          <col2-item class="f-wh">
            <el-form-item label="所属部门" prop="orgId"
              :rules="[ { required: true, message: '请选择所属部门', trigger: 'change' } ]"
            >
              <select-tree
                :value.sync="form.orgId"
                :data.sync="treeSelectData"
                placeholder="查询所属部门"
                :treeData="treeData"
                :treeProps="treeProps"
                :highlightCurrent="true"
                :filterNodeMethod="filterNodeMethod"
                :expand-on-click-node="false"
              />
            </el-form-item>
            <el-form-item label="工号" prop="jobNum"
              :rules="[ { required: true, message: '请输入工号', trigger: 'blur' } ]"
            >
              <v-input v-model="form.jobNum" placeholder="请输入工号" :width="width"/>
            </el-form-item>
            <el-form-item label="职级" prop="jobRank">
              <v-select v-model="form.jobRank" :options="jobRankOps" :width="width"/>
            </el-form-item>
            <el-form-item label="入职日期" prop="jobEnterDate">
              <v-datepicker v-model="form.jobEnterDate" type="date" format="YYYY-MM-DD" :width="width"/>
            </el-form-item>
          </col2-item>
          <col2-item class="f-wh2">
            <el-form-item label="岗位" prop="duty"
              :rules="[{required: true, message: '请输入岗位', trigger: ['blur', 'change']}]"
            >
              <v-input v-model="form.duty" placeholder="请输入岗位" :width="width"/>
            </el-form-item>
            <el-form-item label="公司邮箱" prop="jobEmailAddress"
              :rules="[ { validator: validateEmail, trigger: 'blur' } ]"
            >
              <v-input v-model="form.jobEmailAddress" placeholder="请输入公司邮箱" :width="width"/>
            </el-form-item>
            <el-form-item label="薪档" prop="salaryGrade">
              <v-select v-model="form.salaryGrade" :options="salaryGradeOps" :width="width"/>
            </el-form-item>
            <el-form-item label="离职时间" prop="jobQuitDate">
              <v-datepicker v-model="form.jobQuitDate" type="date" format="YYYY-MM-DD" :width="width"/>
            </el-form-item>
          </col2-item>
        </col2-block>
        <col2-block title="账号管理">
          <!-- 这里多出的输入框是为了避免浏览器默认填充账号密码 -->
          <el-form-item label="用户名" prop="loginName"
            :rules="[{required: !loginNameDisabled, message: '请输入用户名', trigger: ['blur', 'change']}]"
          >
            <v-input v-model="form.loginName"
              placeholder="请输入用户名"
              :disabled="!!loginNameDisabled"
              :width="width" />
          </el-form-item>
          <v-input style="position:fixed;bottom: -9999px;" />
          <v-input type="password" style="position:fixed;bottom: -9999px;" />
          <div style="display: flex;" v-if="!id">
            <el-form-item label="用户密码" prop="pwd"
              :rules="[{ required: true, validator: validatePass1, trigger: 'blur' }]"
            >
              <v-input ref="input1" v-model="form.pwd" type="password" placeholder="请输入用户密码" :width="width" autocomplete="new-password"/>
            </el-form-item>
            <v-input type="password" style="position:fixed;bottom: -9999px;" />
            <el-form-item label-width="20px" prop="pwd2"
              :rules="[{required: true, validator: validatePass, trigger: 'blur'}]"
            >
              <v-input
                ref="input2"
                v-model="form.pwd2"
                type="password"
                placeholder="请确认用户密码"
                autocomplete="new-password"
                :width="width"
              />
            </el-form-item>
          </div>
          <!-- end -->
          <el-form-item label="账号类型" prop="roleType"
            :rules="[ { required: true, message: '请选择账号类型', trigger: 'change' } ]"
          >
            <v-select
              v-model="form.roleType"
              :options="roleTypeFilterOps"
              :disabled="!!defaultRoleType"
              :width="width"
              @change="roleTypeChange"
            />
          </el-form-item>
          <div v-if="form.roleType != '107' && form.roleType">
            <el-form-item label="管理范围" prop="organizeId"
              :rules="[ { required: true, message: '请选择管理范围', trigger: 'change' } ]"
            >
              <v-select2
                style="display: inline-block"
                placeholder="查询管理范围"
                v-model="form.organizeId"
                :subjoin="roleExtraParams"
                v-bind="organizeParams"
                :disabled="!!defaultOrganizeId"
                :width="width"
                @onChange="organizeChange"
              />
              <span class="mar-l">注：请选择管理项目或网格范围</span>
            </el-form-item>
            <el-form-item label="角色" prop="roleId"
              :rules="[ { required: true, message: '请选择角色', trigger: 'change' } ]"
            >
              <v-select2
                placeholder="查询角色"
                v-model="form.roleId"
                :subjoin="rolesExtraParams"
                v-bind="roleParams"
                :width="width"
              ></v-select2>
            </el-form-item>
          </div>
          <el-form-item label="账号状态" prop="accountStatus">
            {{statusMap[form.accountStatus]}}
            <v-button text="关闭" type="text" permission="delete"
              class="mar-l" v-if="!!id && form.loginName !== 'ad_01'"
              v-show="form.accountStatus === 0" @click="updateStatus(0)"
            />
            <v-button text="开启" type="text" permission="delete"
              class="mar-l" v-if="!!id && form.loginName !== 'ad_01'"
              v-show="form.accountStatus === 2" @click="updateStatus(1)"
            />
            <v-button text="重置密码" type="text" permission="resetPwd"
               class="mar-l" v-if="!!id && form.loginName !== 'ad_01'"
               v-show="form.accountStatus === 0" @click="resetPwd"
            />
            <v-button text="解锁" type="text" permission="unlocked"
               class="mar-l" v-if="!!id && form.loginName !== 'ad_01'"
               v-show="form.accountStatus === 0" @click="unlockID"
            />
          </el-form-item>
          <el-form-item label="账号有效截止日期" prop="accountValidDate"
            :rules="[ { required: true, message: '请选择账号有效截止日期', trigger: 'change' } ]"
          >
            <v-datepicker type="date" format="YYYY-MM-DD"
              v-model="form.accountValidDate"
              :width="width" :minDate="new Date()"
            />
            <span class="mar-l">注：截止到当日23:59:59</span>
          </el-form-item>
          <template v-if=" form.roleType === '107' ||
            (form.roleType === '101' && form.organizeId)"
          >
            <el-form-item
              label="所属组织"
              :required="form.roleType === '107'"
              prop="roleType"
              :rules="[{ validator: validatorOrgId, trigger: 'change' }]"
            >
              <chosenListPanel
                :list.sync="orgList"
                @select="toSelectOrg"
              ></chosenListPanel>
            </el-form-item>
          </template>
          <el-form-item v-if="oauthShow" label="授权物业服务" prop="oauth">
            <div class="check-panel">
              <v-checkbox
                v-model="item.checked"
                v-for="(item, index) in oauthList"
                :key="index"
                :index="index"
                type="border"
                :label="item.name"
                @change="val => authSelect(val, item, index)"
              />
            </div>
            <div style="color: #b75151">
              注：授权服务之后，该用户如果具有园区管理app“服务工单”的权限，就可以查看上述选中服务的工单.
            </div>
          </el-form-item>
          <el-form-item v-if="oauthStoreShow" label="授权门店" prop="oauth">
            <div class="check-panel">
              <v-checkbox
                v-model="item.checked"
                v-for="(item, index) in oauthStoreList"
                :key="index"
                :index="index"
                type="border"
                :label="item.name"
                @change="val => authStoreSelect(val, item, index)"
              />
            </div>
          </el-form-item>
        </col2-block>
      </col2-detail>
      <template #saveBeforeSlot>
        <v-button v-if="showReturn" text="返回" @click="returnClick" />
        <v-button type="success" v-loading="loading" text="保存" @click="submitBefore" />
      </template>
    </form-panel>
    <multi-select
      title="组织列表"
      :isShow.sync="isShow"
      :searchUrl="getOrgRelationListURL"
      :extraParams="extraParams"
      :headers="orgTableHeader"
      :searchParams="searchParams"
      :responseParams="responseParams"
      :responseKey="responseKey"
      :backFill.sync="orgList"
      @callback="onOrgSelect"
    >
      <template #searchSlot>
        <v-input v-model="searchParams.orgName" label="组织名称"></v-input>
        <v-select2
          v-model="searchParams.orgTagId"
          v-bind="orgTagParams"
          label="组织标签"
        ></v-select2>
        <v-select2
          v-if="form.roleType === '107'"
          v-model="searchParams.roleId"
          :subjoin="roleExtraParams"
          v-bind="roleParams"
          label="角色"
        ></v-select2>
        <v-select2
          v-if="form.roleType !== '101'"
          v-model="searchParams.communityId"
          v-bind="communityParams"
          label="关联项目"
        ></v-select2>
      </template>
    </multi-select>
  </div>
</template>

<script>
import _ from 'lodash'
// import { uploadURL } from 'common/api'
import {
  getRolesURL,
  getOrganizeURL,
  getRoleTypeURL,
  addManageUserURL,
  updateManageUserURL,
  getUserInfoURL,
  updateTypeURL,
  getViewOauthURL,
  getStoreListURL,
  getOrgRelationListURL,
  getDropDownSearchTagURL,
  resetPwgURL,
  unlockedURL,
  batchURL,
  getChinaAreaTreeURL,
  uploadURL,
  // 新接口
  updateEnterpriseUserInfoURL,
  addEnterpriseUserInfoURL,
  getEnterpriseUserInitCodeWordListURL,
  getEnterpriseUserInfoDetailURL,
  getEnterOrgTreeListURL
} from './api'
import {
  statusOps,
  statusMap,
  sexOps,
  sexMap
} from './map'
import {
  Col2Detail,
  Col2Block,
  Col2Item,
  ChosenListPanel,
  SelectTree,
  MultiSelect,
  AreaSelect
} from 'components/bussiness'
import { vCheckbox } from 'components/control/index'
// import { vCascader, vUploader } from 'components/control'
import { vUploader } from 'components/control'
import { mobileRegular, idNumberRegular, emailAddressRegular } from 'common/regular'
import { communityParams } from 'common/select2Params'

export default {
  name: 'memberInfoForm',
  props: {
    setBreadcrumb: {
      type: Boolean,
      default: true
    },
    showReturn: {
      type: Boolean,
      default: false
    },
    defaultId: String,
    enterpriseUserInfoId: String,
    defaultRoleType: String,
    defaultOrganizeId: String,
    defaultRoleId: String,
    isSystemAdded: Number, // 用于区分是否TenantForm嵌入页面的成员
    orgTypeNum: { // TenantForm 页面为租户,采用默认值,后续若该页面嵌入其他模块,可能需要启用该字段
      type: Number,
      default: 2 // 组织类型 2 表示物业管理公司
    },
    org: {
      type: Object,
      default: null
    }
  },
  components: {
    Col2Detail,
    Col2Block,
    Col2Item,
    ChosenListPanel,
    SelectTree,
    MultiSelect,
    vCheckbox,
    // vCascader,
    AreaSelect,
    vUploader
  },
  data () {
    return {
      width: 182,
      width2: 505,
      pageLoading: false, // 页面加载..
      loading: false, // 保存按钮加载..
      id: '',
      newId: '', // 获取新接口详情
      orgId: '', // 组织id
      orgParentId: '', // 组织父id
      orgType: undefined, // 组织类型
      uploadURL,
      submitContentType: 'application/json;charset=UTF-8',
      config: {
        queryUrl: getUserInfoURL
      },
      nativePlaceArray: [],
      form: {
        // old
        id: '',
        userName: '',
        sex: undefined, // 新加 性别
        birthday: '', // 新加 生日
        emailAddress: '', // 新加 个人邮箱
        phone: '', // 手机号
        loginName: undefined,
        pwd: '',
        pwd2: '',
        duty: '',
        roleType: undefined,
        roleId: '',
        oauth: '',
        storeIds: '',
        organizeId: '',
        // headIcon: undefined,
        desensitization: false,
        roleIds: undefined,
        orgIds: undefined,
        isSystemAdded: this.isSystemAdded,

        // new
        orgId: '', // 组织id
        mobileNum: '', // 手机号
        cardType: undefined,
        cardNum: '',
        country: undefined,
        nation: undefined,
        // nativePlace: '',
        nativePlaceProvince: "", //籍贯
        nativePlaceCity: "",
        nativePlaceArea: "",
        phoneNum: '',
        facePhoto: '',
        fertilityStatus: undefined,
        maritalStatus: undefined,
        graduateSchool: '',
        major: '',
        graduateDate: '',
        educationDegree: undefined,
        registeredResidenceProvince: '',
        registeredResidenceCity: '',
        registeredResidenceArea: '',
        registeredResidenceAddress: '',
        currentResidenceProvince: '',
        currentResidenceCity: '',
        currentResidenceArea: '',
        currentResidenceAddress: '',
        cardPhotoFront: '',
        cardPhotoBack: '',
        jobNum: '',
        jobEmailAddress: '',
        jobRank: undefined,
        salaryGrade: undefined,
        jobEnterDate: '',
        jobQuitDate: '',
        accountStatus: 0, // 账号状态,默认有效
        accountValidDate: '',

        firstWorkTime: '', // 首次参加工作时间
      },
      oldForm: { // 原有页面的字段 - accountMgrForm
        id: '',
        userName: '',
        sex: '',
        birthday: '',
        emailAddress: '',
        phone: '',
        loginName: undefined,
        pwd: '',
        pwd2: '',
        duty: '',
        roleType: undefined,
        roleId: '',
        oauth: '',
        storeIds: '',
        organizeId: '',
        desensitization: false,
        roleIds: undefined,
        orgIds: undefined,
        isSystemAdded: this.isSystemAdded
      },
      newForm: { // 新页面的字段 - 2020.12.9 更改
        // id: '', // 此id与老接口获取详情id并不相同
        orgId: '', // 组织id
        mobileNum: '',
        cardType: undefined,
        cardNum: '',
        country: '',
        nation: '',
        // nativePlace: '',
        nativePlaceProvince: "", //籍贯
        nativePlaceCity: "",
        nativePlaceArea: "",
        phoneNum: '',
        facePhoto: '',
        fertilityStatus: '',
        maritalStatus: '',
        graduateSchool: '',
        major: '',
        graduateDate: '',
        educationDegree: '',
        registeredResidenceProvince: '',
        registeredResidenceCity: '',
        registeredResidenceArea: '',
        registeredResidenceAddress: '',
        currentResidenceProvince: '',
        currentResidenceCity: '',
        currentResidenceArea: '',
        currentResidenceAddress: '',
        cardPhotoFront: '',
        cardPhotoBack: '',
        jobNum: '',
        jobEmailAddress: '',
        jobRank: undefined,
        salaryGrade: undefined,
        jobEnterDate: '',
        jobQuitDate: '',
        accountStatus: '', // 账号状态
        accountValidDate: '',
        firstWorkTime: '', // 首次参加工作时间
      },
      /* 级联选择器 */
      getChinaAreaTreeURL,
      areaList1: [],
      areaList2: [],
      /* select2控件 */
      organizeParams: {
        searchUrl: getOrganizeURL,
        request: {
          text: 'orgName_',
          value: 'orgId_'
        }
      },
      roleParams: {
        searchUrl: getRolesURL,
        request: {
          text: 'roleName_',
          value: 'roleId_'
        }
      },
      oauthList: [], // 授权物业服务列表
      oauthStoreList: [], // 授权门店列表
      /* 控件选项 */
      userSexOps: [],
      sexOps: sexOps(2),
      sexMap,
      statusOps,
      statusMap,
      roleTypeOps: [], // 账号类型
      countryOps: [],
      nationOps: [],
      fertilityStatusOps: [],
      maritalStatusOps: [],
      educationDegreeOps: [],
      jobRankOps: [],
      salaryGradeOps: [],
      cardTypeOps: [],
      /* select-tree 控件 */
      treeSelectData: {}, // 所选树节点的数据
      treeProps: {
        id: 'id', // 必须
        parentId: 'parentId',
        children: 'childOrgList', // 必须
        label: 'orgName', // 必须
        // isLeaf: (data, node) => {    // 必须
        //   return data.hasChild === 0
        // },
        btn: 'operateArr' // 必须
      },
      treeData: [],
      /* 多选框控件 */
      getOrgRelationListURL,
      isShow: false,
      communityParams,
      searchParams: {
        orgName: '',
        orgTagId: '',
        roleId: undefined,
        communityId: ''
      },
      orgTagParams: {
        searchUrl: getDropDownSearchTagURL,
        request: {
          text: 'tagName',
          value: 'tagId'
        },
        response: {
          text: 'tagName',
          value: 'id'
        }
      },
      responseKey: {
        id: 'id',
        name: 'text'
      },
      orgList: [],
      /* 辅助状态 */
      oauthIds: [], // 授权物业ids
      oauthStoreIds: [], // 授权门店ids
      roleType: '', // 记录初始的角色类型
      userName: '', // 记录初始的用户名
      loginName: '', // 记录初始的登录名
      isBack: false // 获取页面详情时,账号类型修改不清空相关数据
    }
  },
  computed: {
    /* 全局变量 */
    userInfo () {
      return this.$store.state.userInfo
    },
    editMobile () { // 手机号控件是否禁用
      return this.$store.getters.getPermission('editMobile')
    },
    /* 表单提交and请求 */
    extraParams () {
      let params = { roleType: this.form.roleType }
      if (this.form.roleType === '101') {
        params = {
          roleType: this.form.roleType,
          communityId: this.form.organizeId
        }
      }
      return params
    },
    submitUrl () {
      return this.id ? updateManageUserURL : addManageUserURL
    },
    submitUrl2 () {
      return this.id ? updateEnterpriseUserInfoURL : addEnterpriseUserInfoURL
    },
    submitMethod () {
      return this.id ? 'PUT' : 'POST'
    },
    /* select2 控件 */
    roleExtraParams () { // 管理范围
      return {
        roleType: this.form.roleType
      }
    },
    rolesExtraParams () { // 角色
      let organizeId = ''
      if (this.userInfo.userType === '101') {
        organizeId = this.userInfo.orgId
      }
      return {
        roleType: this.form.roleType,
        organizeId: organizeId || this.form.organizeId
      }
    },
    /* vcheckbox 多选项 */
    oauthShow () { // 授权物业服务
      let arr = ['100', '101', '102']
      return arr.indexOf(this.form.roleType) > -1
    },
    oauthStoreShow () {
      let arr = ['101']
      return arr.indexOf(this.form.roleType) > -1
    },
    /* 多选框 */
    orgTableHeader () { // 多选框表头
      let show = this.form.roleType === '107'
      return [
        {
          prop: 'orgName',
          label: '组织名称'
        },
        {
          prop: 'orgTagNames',
          label: '组织标签'
        },
        {
          prop: 'communityNames',
          label: '关联项目'
        },
        {
          prop: 'roleName',
          label: '角色名称',
          show: show
        }
      ]
    },
    responseParams () { // 多选框响应字段
      let responseParams1 = {
        id: 'orgId',
        name: 'orgName'
      }

      let responseParams2 = {
        id: 'id',
        name: ['orgName', 'roleName']
      }

      return this.form.roleType === '101' ? responseParams1 : responseParams2
    },
    /* 过滤 */
    roleTypeFilterOps () {
      // 2/3 物业管理公司/社区,账号只能选集团以下的角色, 1 平台职能部门 只能选 集团+系统
      if (Number(this.orgType) === 1) {
        return this.roleTypeOps.filter(v => v.value === '100' || v.value === '106')
      } else if (Number(this.orgType) === 2 || Number(this.orgType) === 3) {
        return this.roleTypeOps.filter(v => v.value !== '100' && v.value !== '106')
      }
      return this.roleTypeOps
    },
    /* 控件的显隐以及禁用 */
    loginNameDisabled () { // 登录名禁止修改
      let arr = ['100', '102', '101', '107']
      return (arr.indexOf(this.form.roleType) < 0 || (this.id && this.id.length > 0))
    }
  },
  watch: {
    defaultOrganizeId (newValue) {
      this.form.organizeId = newValue
    },
    defaultRoleId (newValue) {
      this.form.roleId = newValue
    }
  },
  created () {
    /** 通过该页面goBack方法返回的页面需要加上下面注释的代码 */
    // const orgTree = sessionStorage.getItem('orgTree')
    // if (orgTree) {
    //   sessionStorage.removeItem('orgTree')
    // }
    /** end */
    // 1. 获取旧接口获取详情id
    this.id = this.$route.query.id || ''
    // 2. 获取组织id 和 类型
    this.form.orgId = this.$route.query.orgId || ''
    this.orgId = this.$route.query.orgId || ''
    this.orgParentId = this.$route.query.orgParentId || ''
    // this.orgType = this.orgTypeNum || this.$route.query.orgType
    this.orgType = this.$route.query.orgType || ''
    // 3. 获取新表成员id
    this.newId = this.$route.query.newId || ''
  },
  mounted () {
    /** 以下走嵌入页面逻辑 */
    if (this.org) {
      console.log(this.org.orgType, 'this.org--------')
      this.orgType = this.org.orgType || ''
      this.orgId = this.org.orgId || ''
      this.orgParentId = this.orgParentId || ''
    }
    if (this.defaultId) {
      this.id = this.defaultId
    }
    if (this.enterpriseUserInfoId) {
      this.newId = this.enterpriseUserInfoId
    }
    this.id && this.$refs.form.getData({ userId: this.id })
    if (this.setBreadcrumb) {
      this.id ? this.$setBreadcrumb('编辑') : this.$setBreadcrumb('新增')
    }
    if (!this.id) {
      if (this.defaultRoleType) {
        this.form.roleType = this.defaultRoleType
      }
    }
    this.getTreeList()
    this.getRoleType()
    this.getSelectList()
  },
  methods: {
    goBack () {
      sessionStorage.setItem('orgTree', JSON.stringify({
        orgId: this.orgId,
        orgParentId: this.orgParentId,
        orgType: this.orgType
      }))
      this.$router.go(-1)
    },
    returnClick () { // 返回按钮,用于该页面被嵌套时
      this.$emit('returnClick')
    },
    update (data) {
      this.isBack = true
      /**
       * 老旧接口，无详细业务，且后端无档期查询接口逻辑，因而保留原有代码
       * 请求详情接口为最早的old接口，与第一次旧接口保存字段并非一一对应，所以衍生出此代码
       *
      */
      // 1. 字段匹配值
      this.form.id = data.id
      this.form.sex = data.sex
      this.form.birthday = data.birthday
      this.form.emailAddress = data.emailAddress || ''
      this.form.duty = data.duty
      this.form.loginName = data.loginName
      this.form.phone = data.phone
      this.form.desensitization = !data.desensitization
      this.form.userName = data.userName
      // this.newseeUserName = data.nsUserName
      this.form.organizeId = data.orgId
      this.form.roleId = data.roleId
      this.form.roleType = JSON.stringify(data.userType)
      // 2. 记录部分字段的初始状态
      this.userName = data.userName
      this.loginName = data.loginName
      this.roleType = JSON.stringify(data.userType)
      // 3. 组织多选框的列表根据权限显示不同数据
      if (this.form.roleType === '107') {
        this.orgList = data.belongOrgList && data.belongOrgList.length > 0
          ? data.belongOrgList.map(item => ({
            text: item.orgRoleName,
            id: item.roleId,
            obj: item
          })) : []
      }
      if (this.form.roleType === '101') {
        this.orgList = data.belongOrgList && data.belongOrgList.length > 0
          ? data.belongOrgList.map(item => ({
            text: item.orgName,
            id: String(item.orgId),
            obj: item
          })) : []
      }
      // 4. 请求新接口详情
      this.upload2Request()
      /** end */
      // Object.keys({ ...this.form, id: '' }).forEach(key => {
      //   this.form[key] = data[key] || this.getEmptyValue(data[key], this.form[key])
      // })
    },
    upload2 (data) {
      if (!data) { return }
      Object.keys({ ...this.newForm }).forEach(key => {
        if (key === 'id') { // form字段id为旧id,不能覆盖
          return
        }
        this.form[key] = data[key] || this.getEmptyValue(data[key], this.form[key])
      })
      this.$set(this.newForm, 'id', data.id)
      // 级联选择器
      this.areaList1 = [data.registeredResidenceProvince, data.registeredResidenceCity, data.registeredResidenceArea]
      this.areaList2 = [data.currentResidenceProvince, data.currentResidenceCity, data.currentResidenceArea]
      this.nativePlaceArray = [
        data.nativePlaceProvince,
        data.nativePlaceCity,
        data.nativePlaceArea,
      ];
    },
    /* 表单请求之前and请求成功 */
    async submitBefore () { // 1. 调保存接口前
      // 0. 先验证表单 注释内容存在问题
      // let result = this.$refs.form.validate()
      this.$refs.form.$refs.form.validate((result) => {
        if (!result) {
          return
        }
        // 1. 深度克隆表单数据
        const oldParams = _.cloneDeep(this.form)
        this.setOldParams(oldParams)
      })
    },
    setOldParams (params) { // 2. 设置老接口参数
      // 1. 去除掉值为undefined的字段
      let oldForm = {}
      let keys = Object.keys(this.oldForm)
      keys.forEach(k => {
        if (params[k] !== undefined) {
          oldForm[k] = params[k]
        }
      })
      // 2. 字段类型修改
      oldForm.desensitization = oldForm.desensitization ? 0 : 1
      // 3. 字段名称修改
      oldForm.mobileNum = oldForm.phone
      oldForm.adminDuty = oldForm.duty
      oldForm.password = oldForm.pwd
      oldForm.userType = oldForm.roleType
      oldForm.orgId = oldForm.organizeId
      // 4. 字段值填充
      this.orgList.length > 0 && (oldForm.orgIds = this.orgList.map((item) => item.id))
      this.oauthIds.length > 0 && (oldForm.subIds = this.oauthIds)
      this.oauthStoreIds.length > 0 && (oldForm.storeIds = this.oauthStoreIds)
      // 4. 区分新增和编辑，编辑需要保留原有字段, 新增则不需要
      if (!this.id) {
        delete oldForm.phone
        delete oldForm.duty
        delete oldForm.pwd
        delete oldForm.pwd2
        delete oldForm.roleType
        delete oldForm.organizeId
        delete oldForm.oauth
      } else {
        oldForm.userId = oldForm.id
      }
      // 5. 权限
      if (oldForm.userType !== '101') {
        delete oldForm.orgIds
      }
      if (this.form.roleType === '107') {
        oldForm.roleIds = this.orgList.map(item => item.id)
        this.orgList[0].obj.orgId && (oldForm.orgId = this.orgList[0].obj.orgId) // 组织账号默认
        oldForm.roleId = oldForm.roleIds[0]
      }
      // 6. 未知业务,暂不清楚为何如此写
      oldForm.logingName = oldForm.loginName
      // 添加操作对象参数 规则：账号名称-登录名
      let dataObject = `${oldForm.userName}`
      if (oldForm.loginName) {
        dataObject += `-${oldForm.loginName}`
      }
      oldForm.dataObject = dataObject
      // 7. 调请求接口,注意老接口请求成功后再请求新接口
      this.requestOldSubmit(oldForm)
    },
    async submitSuccess (data, msg) { // 3. 老接口保存成功后
      console.log('0. 开始调新接口')
      let onoff = await this.setNewParams()
      if (onoff) {
        console.log('5. 新表接口正常')
      }
      if (this.id) {
        this.openAllConfig(this.id)
      } else {
        this.openAllConfig(data)
        // 当角色权限不为区域、集团、项目时，data可能是账号名的提示
        data && !(/^(\d|\w|-)+$/.test(data)) && (await this.$alert(data))
      }
      return true
    },
    async openAllConfig (id) { // 4-1. 物管端APP待办项设置————区域、项目、集团
      // 100-区域、101-项目、102-集团
      let arr = ['100', '101', '102']
      if (this.id) {
        if (arr.includes(this.form.roleType) && !arr.includes(this.roleType)) {
          // 编辑 - 如果修改后的账号类型符合要求,且原先的账户类型不符合要求,则调接口
          this.requestToAllConfig(id)
        }
      } else if (!this.id && arr.includes(this.form.roleType)) {
        // 新增 - 选择的账号类型符合要求，则调接口
        this.requestToAllConfig(id)
      }
    },
    async setNewParams () { // 4-2. 新接口
      const params = _.cloneDeep(this.form)
      let newForm = {}
      Object.keys({ ...this.newForm }).forEach(k => {
        if (k === 'id') { // 此处id不能用form,而必须是newForm的id
          newForm[k] = this.newForm[k]
          return
        }
        newForm[k] = params[k]
        if (k === 'mobileNum') {
          newForm[k] = params['phone']
        }
      })
      const result = await this.requestNewSubmit(newForm)
      return result
    },
    /* 接口类 */
    upload2Request () {
      this.pageLoading = true
      this.$axios.get(`${getEnterpriseUserInfoDetailURL}?id=${this.newId}`)
        .then((res) => {
          if (res.status === '100' || res.status === 100) {
            const { data } = res
            this.upload2(data)
          }
        }).finally(() => {
          this.pageLoading = false
        })
    },
    async requestOldSubmit (params) {
      console.log(params)
      this.loading = true
      let config = {
        method: this.submitMethod,
        url: this.submitUrl,
        headers: {
          'Content-Type': this.submitContentType
        },
        data: params,
        transformRequest: [function (data) { return JSON.stringify(data) }]
      }
      this.$axios(config).then(async res => {
        if (res.status === 100 || res.status === '100') {
          // let data = res.data
          // let msg = res.msg
          console.log('1. 老接口请求成功')
          let { data, msg } = res
          let result = await this.submitSuccess(data, msg)
          console.log(this.orgId, this.orgParentId, '6.orgParentId 和 this.orgId')
          if (!result) {
            return
          }
          this.$message.success('保存成功')
          this.goBack()
        } else {
          this.loading = false
          // this.submitError && this.submitError(res)
        }
      })
    },
    async requestNewSubmit (params) {
      console.log('3. 新表接口开始调')
      let result = false
      let res = await this.$axios.post(this.submitUrl2, params)
      console.log('4. 新表接口调成功')
      this.loading = false
      if (Number(res.status) === 100 && res.msg === 'ok') {
        console.log(JSON.stringify(this.treeSelectData), '5. 修改orgId和orgParentId')
        this.orgId = this.treeSelectData[this.treeProps.id]
        this.orgParentId = this.treeSelectData[this.treeProps.parentId]
        result = true
      }
      return result
    },
    getRoleType () { // 账号类型 100/101/102...
      this.$axios.get(getRoleTypeURL).then((res) => {
        if (res.status === '100') {
          const { data } = res
          this.roleTypeOps = data.map((item) => {
            return {
              text: item.name,
              value: item.id
            }
          })
          console.log(this.form.roleType)
          // !this.form.roleType && (this.form.roleType = data[0].id)
        }
      })
    },
    getTreeList () { // 所属部门树数据
      this.$axios.get(`${getEnterOrgTreeListURL}?orgType=${this.orgType}`)
        .then((res) => {
          if (res.status === 100 && res.msg === 'ok') {
            this.treeData = res.data
            this.keyTree += 1
          }
        })
    },
    getSelectList () { // 其余下拉选项数据
      this.$axios.get(getEnterpriseUserInitCodeWordListURL).then((res) => {
        if (res.status === 100 && res.data) {
          const {
            countryList,
            educationDegreeList,
            fertilityList,
            isMarryList,
            jobRankList,
            nationList,
            salaryGradeList,
            cardTypeList
          } = res.data
          this.countryOps = countryList.map(item => ({ text: item, value: item }))
          this.educationDegreeOps = educationDegreeList.map(item => ({ text: item, value: item }))
          this.fertilityStatusOps = fertilityList.map(item => ({ text: item, value: item }))
          this.maritalStatusOps = isMarryList.map(item => ({ text: item, value: item }))
          this.jobRankOps = jobRankList.map(item => ({ text: item, value: item }))
          this.nationOps = nationList.map(item => ({ text: item, value: item }))
          this.salaryGradeOps = salaryGradeList.map(item => ({ text: item, value: item }))
          this.cardTypeOps = cardTypeList.map((item, index) => ({
            text: item,
            value: item,
          }));
        }
      })
    },
    requestToAllConfig (id) { // 2.1 开启邑生活物管端APP待办项设置的请求接口
      this.$axios.post(`${updateTypeURL}?userId=${id}`).then((res) => {
        if (res.status === 100) {
          console.log('成功了')
        }
      })
    },
    getViewOauthList (id) { // 其余控件方法 - 管理范围 - 授权物业
      let params = {
        organizeId: id,
        userType: this.form.roleType
      }
      // this.id && (params.userId = this.id)
      if (this.id) {
        params.userId = this.id
      }
      this.$axios.post(getViewOauthURL, params).then((res) => {
        if (res.status === '100') {
          let oauthList = []
          let ids = []
          res.data && res.data.forEach((item) => {
            if (item.selected || item.name === '集团报事') {
              ids.push(item.id)
            }
            oauthList.push({
              id: item.id,
              name: item.name,
              checked: item.name === '集团报事' ? true : Number(item.selected) === 1
            })
          })
          this.oauthList = oauthList
          this.oauthIds = ids
        }
      })
    },
    getViewOauthStoreList (id) { // 其余控件方法-管理范围-授权门店
      let data = {
        communityId: id,
        userType: this.form.roleType
      }
      this.id && (data.userId = this.id)
      this.$axios.get(getStoreListURL, { params: data }).then((res) => {
        if (res.status === 100) {
          let oauthList = []
          let ids = []
          res.data && res.data.forEach((item) => {
            if (item.selected) {
              ids.push(item.id)
            }
            oauthList.push({
              id: item.id,
              name: item.name,
              checked: Number(item.selected) === 1
            })
          })
          this.oauthStoreList = oauthList
          this.oauthStoreIds = ids
        }
      })
    },
    resetPwd () { // 重置密码
      this.$axios.put(
        resetPwgURL,
        this.$qs.stringify({ userId: this.id })
      ).then((res) => {
        if (res.status === 100) {
          this.$alert(res.data)
        }
      })
    },
    unlockID () { // 解锁
      this.$axios.post(
        unlockedURL,
        {
          userId: this.id,
          userName: this.loginName
        }
      ).then((res) => {
        if (Number(res.status) === 100) {
          this.$alert('账号解锁成功！')
        }
      })
    },
    updateStatus (status) { // 关闭or开启账号
      // 操作日志添加操作对象 规则：userName-loginName
      // let dataObject = dataArr.join(';')
      let dataObject = `${this.userName}-${this.loginName}`

      let _this = this
      let postData = {
        userIds: this.id,
        status, // 0 关闭 1 开启
        dataObject: dataObject
      }
      _this.$axios({
        method: 'put',
        url: batchURL,
        data: postData
      }).then(res => {
        if (res.status === 100) {
          _this.$refs.form.getData({ userId: this.id })
          _this.$message({
            type: 'success',
            message: '操作成功',
            center: true
          })
        }
      })
    },

    /* 其余控件方法 */
    toSelectOrg () { // 打开组织多选框
      this.isShow = true
    },
    areaChange1 (val) { // 户口所在地
      if (val.area) {
        this.form.registeredResidenceCity = val.city.code
        this.form.registeredResidenceArea = val.area.code
        this.form.registeredResidenceProvince = val.province.code
      } else {
        this.form.registeredResidenceCity = ''
        this.form.registeredResidenceArea = ''
        this.form.registeredResidenceProvince = ''
      }
    },
    areaChange2 (val) { // 现居住地
      if (val.area) {
        this.form.currentResidenceCity = val.city.code
        this.form.currentResidenceArea = val.area.code
        this.form.currentResidenceProvince = val.province.code
      } else {
        this.form.currentResidenceCity = ''
        this.form.currentResidenceArea = ''
        this.form.currentResidenceProvince = ''
      }
    },
    nativePlaceChange(val) {
      //籍贯
      if (val.area) {
        this.form.nativePlaceProvince = val.province.code;
        this.form.nativePlaceCity = val.city.code;
        this.form.nativePlaceArea = val.area.code;
      } else {
        this.form.nativePlaceProvince = "";
        this.form.nativePlaceCity = "";
        this.form.nativePlaceArea = "";
      }
    },
    roleTypeChange () { // 账号类型
      if (this.isBack) {
        this.isBack = false
        return false
      }
      this.orgList = []
      this.oauthList = []
      this.oauthIds = []
      this.oauthStoreList = []
      this.oauthStoreIds = []
      this.$refs.form.clearValidate()
    },
    organizeChange (val) { // 管理范围
      if (this.oauthShow) { // 如果是授权物业
        if (val && val.id) {
          this.getViewOauthList(val.id)
        } else {
          this.oauthList = []
          this.oauthIds = []
        }
      }
      if (this.oauthStoreShow) { // 如果是授权门店
        if (val && val.id) {
          this.getViewOauthStoreList(val.id)
        } else {
          this.oauthStoreList = []
          this.oauthStoreIds = []
        }
      }
    },
    authSelect (val, item, index) {
      if (val) {
        this.oauthIds.push(item.id)
      } else {
        let index = this.oauthIds.indexOf(item.id)
        this.oauthIds.splice(index, 1)
      }
    },
    authStoreSelect (val, item, index) { // 授权门店
      if (val) {
        this.oauthStoreIds.push(item.id)
      } else {
        let index = this.oauthStoreIds.indexOf(item.id)
        this.oauthStoreIds.splice(index, 1)
      }
    },
    onOrgSelect (data) { // 组织多选框内容
      console.log(data, '多选框选择内容')
    },

    /* 选择树控件 */
    filterNodeMethod (value, data) { // 过滤树节点的方法
      // value 为 this.filterText 的值, data 为当前节点的数据对象-接口获取
      if (!value) return true
      return data[this.treeProps.label].indexOf(value) !== -1
    },

    /* 表单校验 */
    valiAreaList1 (rule, value, callback) { // 户口所在地
      setTimeout(() => {
        if (this.areaList1.length > 0) {
          callback()
        } else {
          callback(new Error('请选择省市区'))
        }
      })
    },
    valiAreaList2 (rule, value, callback) { // 现居住地
      setTimeout(() => {
        if (this.areaList2.length > 0) {
          callback()
        } else {
          callback(new Error('请选择省市区'))
        }
      })
    },
    validatePhone (rule, value, callback) { // 手机号验证
      if (value === '') {
        callback(new Error('请填写手机号'))
      } else if (!mobileRegular.test(value)) {
        callback(new Error('手机号不正确'))
      } else {
        callback()
      }
    },
    validatePhone1 (rule, value, callback) { // 手机号验证
      if (value === '') {
        callback()
        return
      }
      if (!mobileRegular.test(value)) {
        callback(new Error('手机号不正确'))
      } else {
        callback()
      }
    },
    validateCardNum (rule, value, callback) {
      //身份证
      let regExp1 = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/
      //军官证
      let regExp2 = /^[\u4E00-\u9FA5](字第)([0-9a-zA-Z]{4,8})(号?)$/
      //护照
      let regExp3 = /^([a-zA-z]|[0-9]){9}$/
      //驾驶证
      let regExp5 = /^\d{12}$/
      //港澳通行证
      let regExp6 = /^[HMhm]{1}([0-9]{10}|[0-9]{8})$/
      //台胞证
      let regExp7 = /^\d{8}$|^[a-zA-Z0-9]{10}$|^\d{18}$/
      //户口本
      let regExp8 = /^\d{9}$/
      //警官证
      let regExp9 = /^[0-9]{8,9}$/
      let showError = false
      if (value && value.length > 0) {
        if(this.form.cardType == '身份证') {
          if (!regExp1.test(value)) {
            showError = true
          }
        } else if(this.form.cardType == '军官证') {
          if (!regExp2.test(value)) {
            showError = true
          }
        } else if(this.form.cardType == '护照') {
          if (!regExp3.test(value)) {
            showError = true
          }
        } else if(this.form.cardType == '驾驶证') {
          if (!regExp5.test(value)) {
            showError = true
          }
        } else if(this.form.cardType == '港澳通行证') {
          if (!regExp6.test(value)) {
            showError = true
          }
        } else if(this.form.cardType == '台胞证') {
          if (!regExp7.test(value)) {
            showError = true
          }
        } else if(this.form.cardType == '户口本') {
          if (!regExp8.test(value)) {
            showError = true
          }
        } else if(this.form.cardType == '警官证') {
          if (!regExp9.test(value)) {
            showError = true
          }
        }
      }
      if (showError) {
        callback(new Error('证件号码格式不正确'))
      } else {
        callback()
      }
    },
    validateEmail (rule, value, callback) {
      if (value === '') {
        callback()
        return
      }
      if (!emailAddressRegular.test(value)) {
        callback(new Error('邮箱格式不正确'))
      } else {
        callback()
      }
    },
    validatorOrgId (rule, value, callback) { // 所属组织
      if (this.form.roleType !== '107') {
        callback()
      } else if (this.orgList.length === 0) {
        callback(new Error('请选择所属组织'))
      } else {
        callback()
      }
    },
    validatePass1 (rule, value, callback) { // 验证登录密码
      if (value === '') {
        callback(new Error('请输入用户密码'))
      } else if (value.length < 8 || value.length > 12) {
        callback(new Error('密码长度8到12个字符!'))
      } else {
        callback()
      }
    },
    validatePass (rule, value, callback) { // 验证再次输入的密码
      if (value === '') {
        callback(new Error('请确认用户密码'))
      } else if (value.length < 8 || value.length > 12) {
        callback(new Error('密码长度8到12个字符!'))
      } else if (value !== this.form.pwd) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    },

    /** 辅助类方法 */
    inputDefault (e) { // 阻止默认事件
      for (let i = 0; i < 3; i += 1) {
        this.$refs['index' + i].onmousedown = function () {
          if (e && e.preventDefault) {
            // 现代浏览器阻止默认事件
            e.preventDefault()
          } else {
            // IE阻止默认事件
            window.event.returnValue = false
          }
          return false
        }
      }
    },
    getEmptyValue (val, emptyVal) { // 对空值进行判断
      if (val === undefined || val === '' || val === null) {
        return emptyVal
      } else if (val === 0 || val === '0') {
        return 0
      } else if (val === false) {
        return false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.mar-l {
  margin-left: 8px;
}
.f-wh {
  width: 520px;
}
.f-wh2 {
  width: 355px;
}
.memberInfoForm-wrapper {
  .select-tree {
    position: relative;
    .search {
      // ::v-deep .el-input__inner {
      //   border-radius:18px;
      //   background: rgba(144,166,186, 0.1);
      //   box-shadow: inset 4px 1px 8px 0px rgba(95,122,155,0.1);
      //   border: none;
      // }
      ::v-deep .el-input__suffix {
        line-height: 32px;
      }
    }
    .tree-group {
      &::before {
        content: '';
        border: 1px solid;
        border-color: #E4E7ED transparent transparent #E4E7ED;
        background-color: #fff;
        width: 6px;
        height: 6px;
        position: absolute;
        top: 2px;
        left: 36px;
        z-index: 4;
        transform: rotateZ(45deg)
      }
      position: absolute;
      left: 0;
      top: 110%;
      z-index: 2;
      .tree-group__box {
        &.is-empty {
          height: auto;
          color: #909399;
          text-align: center;
          padding: 4px 0;
          font-size: 14px;
        }
        /** 不可使用定位,会破坏button-tree组件结构 */
        width: 182px;
        height: 204px;
        border: 1px solid #E4E7ED;
        border-radius: 4px;
        background-color: #FFF;
        box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
        box-sizing: border-box;
        margin: 5px 0;
        padding: 5px 0 0;
      }
    }
  }
  .check-panel {
    display: flex;
    flex-flow: wrap;
    padding: 10px;
    .checkbox-wrapper {
      margin-right: 5px;
      margin-bottom: 5px;
    }
  }
}
</style>
